import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import bootsratp from 'bootstrap/dist/js/bootstrap.bundle.js'
import "@/assets/scss/style.scss"
import router from './router'
import "@/assets/css/swiper.min.css"
import "@/assets/css/materialdesignicons.min.css"
import { VueTypedJs } from 'vue-typed-js'
import VueEasyLightbox from 'vue-easy-lightbox'


const app = createApp(App)
app.use(VueEasyLightbox)
app.use(VueTypedJs)
app.use(router)
app.use(bootsratp)
app.mount('#app')
