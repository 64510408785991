import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/pages/index-startup.vue"),
  },
  {
    path: "/index-marketing",
    name: "IndexMarketing",
    component: () => import("@/pages/index-marketing.vue"),
  },
  {
    path: "/index-agency",
    name: "IndexAgency",
    component: () => import("@/pages/index-agency.vue"),
  },
  {
    path: "/index-saas",
    name: "IndexSaas",
    component: () => import("@/pages/index-saas.vue"),
  },
  {
    path: "/index-personal",
    name: "IndexPersonal",
    component: () => import("@/pages/index-personal.vue"),
  },
  {
    path: "/index-portfolio",
    name: "IndexPortfolio",
    component: () => import("@/pages/index-portfolio.vue"),
  },

  {
    path: "/auth-login",
    name: "AuthLogin",
    component: () => import("@/pages/auth-login.vue"),
  },
  {
    path: "/auth-signup",
    name: "AuthSignup",
    component: () => import("@/pages/auth-signup.vue"),
  },
  {
    path: "/auth-reset-password",
    name: "AuthResetPass",
    component: () => import("@/pages/auth-reset-password.vue"),
  },
  {
    path: "/comingsoon",
    name: "Comingsoon",
    component: () => import("@/pages/comingsoon.vue"),
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/pages/maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "Error",
    component: () => import("@/pages/error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
