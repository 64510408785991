<template>
  <router-view></router-view>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, provide } from "vue";

function useBreakpoints() {
  let windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const size = computed(() => {
    if (windowWidth.value < 700) return "xs";
    if (windowWidth.value >= 700 && windowWidth.value < 1200) return "md";
    if (windowWidth.value >= 1200 && windowWidth.value < 1600) return "lg";
    if (windowWidth.value >= 1600) return "xl";
    return null; // This is an unreachable line, simply to keep eslint happy.
  });

  return size;
}
provide("screenWidth", useBreakpoints());
</script>

<style></style>
